import React, { useState, useEffect } from 'react';
import { addMemberToTeam, getTeam } from 'services/api/teamsService';
import { getUserInfo } from 'services/api/memberService';
import KeycloakService from 'services/KeycloakService';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const AddMember = ({ teamId, organisationId, onClose, onMemberAdded }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [teamName, setTeamName] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const teamResponse = await getTeam(teamId, organisationId);
        setTeamName(teamResponse.data.name);

        const usersResponse = await getUserInfo(organisationId);
        if (usersResponse) {
          setUsers(usersResponse);
        }
      } catch (error) {
        setError('Failed to load users.');
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [teamId, organisationId]);

  const handleUserChange = e => {
    const userSubject = e.target.value;
    setSelectedUser(userSubject);

    const user = users.find(u => u.user.subject === userSubject);
    if (user) {
      setSelectedRole(user.role);
    } else {
      setSelectedRole('');
    }
  };

  const handleAddMember = async () => {
    try {
      await KeycloakService.updateToken();
      await addMemberToTeam(organisationId, teamId, selectedUser);
      toast.success('Member added successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      onMemberAdded();
      onClose();
    } catch (error) {
      setError('Failed to add member.');
      toast.error('Failed to add member.', {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error('Error adding member:', error);
    }
  };

  return (
    <div className="p-1">
      <div className="flex pb-2 mb-4">
        <h2 className="text-sm font-semibold text-gray-800 lg:text-md dark:text-white">
          Add Member to {teamName}'s team
        </h2>
      </div>
      {loading ? (
        <Loading />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="flex flex-col lg:flex-row lg:gap-2 gap-2">
          <div className="mb-1 ">
            <label
              htmlFor="user-select"
              className="block text-xs font-medium text-gray-700 mb-1 lg:text-sm dark:text-gray-500"
            >
              Select member from organisation
            </label>
            <select
              id="user-select"
              value={selectedUser}
              onChange={handleUserChange}
              className="block w-full text-xs p-1 border mt-2 border-gray-300 rounded-md shadow-md bg-white lg:w-[300px] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out dark:bg-navy-700 dark:text-white"
            >
              <option value="">--Select User--</option>
              {users.length > 0 ? (
                users.map(user => (
                  <option key={user.identity} value={user.user.subject}>
                    {user.user.displayName},{' '}
                    {user.user.email || 'Email not available'}
                  </option>
                ))
              ) : (
                <option value="">No users available</option>
              )}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="role-select"
              className="block text-sm font-medium text-gray-700 dark:text-gray-500"
            >
              Role
            </label>
            <input
              id="role-select"
              type="text"
              value={selectedRole}
              readOnly
              className="block w-full text-xs p-1 mt-2 border border-gray-300 rounded-md shadow-md bg-white lg:w-[300px] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out dark:bg-navy-700 dark:text-white"
            />
          </div>
        </div>
      )}

      <div className="flex justify-start mt-3 space-x-4">
        <button
          onClick={handleAddMember}
          className="bg-customBlue text-white text-xs font-semibold px-4 py-2 rounded-md hover:bg-gray-800 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out  dark:bg-navy-600 dark:hover:bg-navy-700"
        >
          Add Member
        </button>
        <button
          onClick={onClose}
          className="bg-white text-gray-900 text-xs font-semibold  px-4 py-2 rounded-md hover:bg-gray-800 hover:text-white shadow-md hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out  dark:bg-navy-600 dark:hover:bg-navy-700 dark:text-white"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddMember;
