import React, { useState, useEffect, useRef } from 'react';
import { FaEnvelope, FaEllipsisV } from 'react-icons/fa';
import { removeMember } from 'services/api/memberService';
import { formatDateTime } from 'utilities/formatDateTime';
import InviteMember from 'views/client/organisation/components/invite-member/InviteMember';
import RoleBadge from 'utilities/RoleBadge';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const Members = ({ members, loading, onEdit, slug, organizationName }) => {
  const [showInviteMember, setShowInviteMember] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [confirmText, setConfirmText] = useState('');
  const [openMenu, setOpenMenu] = useState(null);
  const menuRef = useRef(null);

  const toggleMenu = namespaceId => {
    setOpenMenu(openMenu === namespaceId ? null : namespaceId);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInviteClick = () => {
    setShowInviteMember(true);
  };

  const handleRemoveClick = member => {
    setMemberToRemove(member);
    setShowConfirmModal(true);
  };

  const handleConfirmRemove = async () => {
    if (memberToRemove && confirmText === 'confirm') {
      try {
        await removeMember(slug, memberToRemove.identity);
        setShowConfirmModal(false);
        toast.success('Member removed successfully.', {
          autoClose: 3000,
          position: 'top-center',
        });
      } catch (error) {
        console.error('Error removing member:', error);
      }
      setShowConfirmModal(false);
      setMemberToRemove(null);
      setConfirmText('');
    } else {
      toast.error('Confirmation text is incorrect.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleCancelRemove = () => {
    setShowConfirmModal(false);
    setMemberToRemove(null);
    setConfirmText('');
  };

  return (
    <div className="">
      {!showInviteMember && (
        <div className="flex justify-between items-center pb-4 mb-1">
          <h2 className="text-xs font-bold text-gray-800 lg:text-lg dark:text-white">
            Organization Members
          </h2>
          <div>
            <button
              onClick={handleInviteClick}
              className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md  dark:bg-navy-600 dark:hover:bg-navy-700"
            >
              <FaEnvelope className="mr-2" />
              Invite Member
            </button>
          </div>
        </div>
      )}

      {showInviteMember ? (
        <div className="mt-4 ">
          <InviteMember
            slug={slug}
            organizationName={organizationName}
            onCancel={() => setShowInviteMember(false)}
          />
        </div>
      ) : members.length === 0 ? (
        <div className="text-center text-gray-500 py-4">
          No members available
        </div>
      ) : (
        <div>
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-400 dark:bg-navy-700">
                  <tr>
                    <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Joined At
                    </th>
                    <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Role
                    </th>
                    <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 transition dark:bg-navy-700 dark:divide-gray-700">
                  {members.map(member => (
                    <tr
                      key={member.identity}
                      className="hover:bg-gray-50 dark:hover:bg-navy-600"
                    >
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        {member.user.displayName}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        {member.user.email}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        {formatDateTime(member.user.createdAt)}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <RoleBadge role={member.role} size="sm" />
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() => toggleMenu(member.identity)}
                          className="text-gray-500 hover:text-gray-700 ml-2"
                        >
                          <FaEllipsisV />
                        </button>
                        {openMenu === member.identity && (
                          <div
                            ref={menuRef}
                            className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-lg dark:bg-navy-700"
                          >
                            <ul className="py-1">
                              <li className="border-t border-gray-200">
                                <a
                                  href="#leave"
                                  className="block px-6 py-2 text-sm text-red-600 hover:bg-gray-50 dark:text-red-500 dark:hover:bg-navy-600"
                                  onClick={() => {
                                    handleRemoveClick(member);
                                  }}
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {showConfirmModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 dark:bg-navy-700 dark:bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[33rem] h-[20rem]">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h3 className="text-lg font-bold">Remove Member</h3>
              <button
                onClick={handleCancelRemove}
                className="text-gray-500 hover:text-gray-800"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <p>Do you want to remove {memberToRemove?.user.email}?</p>
            <input
              type="text"
              placeholder="Type 'confirm' to confirm"
              className="border border-gray-300 p-2 mt-12 w-full"
              value={confirmText}
              onChange={e => setConfirmText(e.target.value)}
            />
            <div className="flex justify-end mt-10">
              <button
                onClick={handleCancelRemove}
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmRemove}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
