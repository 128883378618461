import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashIcon from 'components/icons/DashIcon';

export function SidebarLinks(props) {
  let location = useLocation();
  const { routes, onClose } = props;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const activeRoute = (routePath, layout) => {
    const currentPath = location.pathname;

    const dynamicPathRegex = new RegExp(
      `^${layout}/${routePath.replace(/:([a-zA-Z]+)/g, '[^/]+')}$`,
    );

    return dynamicPathRegex.test(currentPath);
  };

  const createLinks = routes => {
    return routes.map((route, index) => {
      if (route.children) {
        return (
          <div key={index}>
            <Link
              to={route.layout + '/' + route.path}
              onClick={isMobile ? onClose : null}
            >
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path, route.layout)
                        ? 'font-bold text-brand-500 dark:text-white'
                        : 'font-medium text-gray-600'
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{' '}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path, route.layout)
                        ? 'font-bold text-navy-700 dark:text-white'
                        : 'font-medium text-gray-600'
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path, route.layout) && (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                )}
              </div>
            </Link>
            {createLinks(route.children)}
          </div>
        );
      } else {
        return (
          <Link
            key={index}
            to={route.layout + '/' + route.path}
            onClick={isMobile ? onClose : null}
          >
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path, route.layout)
                      ? 'font-bold text-customBlue dark:text-white'
                      : 'font-medium text-gray-600'
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{' '}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path, route.layout)
                      ? 'font-bold text-navy-700 dark:text-white'
                      : 'font-medium text-gray-600'
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path, route.layout) && (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-customBlue dark:bg-brand-400" />
              )}
            </div>
          </Link>
        );
      }
    });
  };

  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
