import React, { useState, useEffect } from 'react';
import { createTenant } from 'services/api/tenantService';
import { getClouds } from 'services/api/cloudService';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { toast } from 'react-toastify';
import CloudIcon from 'utilities/CloudIcon';

function CreateTenant({ onCreate, onCancel }) {
  const [tenant, setTenant] = useState({ name: '', description: '' });
  const [clouds, setClouds] = useState([]);
  const [selectedCloud, setSelectedCloud] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();
        setOrganisations(response.map(org => org.organisation));
        setSelectedOrganisation(
          response.length > 0 ? response[0].organisation : null,
        );
      } catch (error) {
        setError('Failed to load organization data');
        console.error(error);
      }
    };

    fetchOrganisationData();
  }, []);

  useEffect(() => {
    const fetchClouds = async () => {
      try {
        const data = await getClouds();
        setClouds(data);
      } catch (err) {
        setError('Failed to fetch cloud data. Please try again later.');
        console.error(err);
      }
    };

    if (selectedOrganisation) {
      fetchClouds();
    }
  }, [selectedOrganisation]);

  const validateName = name => {
    const regex = /^[a-z0-9][a-z0-9-]{2,48}[a-z0-9]$/;
    return regex.test(name);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setTenant(prevTenant => ({ ...prevTenant, [name]: value }));
  };

  const handleCloudSelect = cloud => {
    setSelectedCloud(cloud);
    setSelectedRegion(null);
  };

  const handleRegionChange = event => {
    const selectedRegionIdentity = event.target.value;
    const region = selectedCloud.regions.find(
      r => r.identity === selectedRegionIdentity,
    );
    setSelectedRegion(region);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setFormError(null);
    setIsSubmitting(true);

    if (!validateName(tenant.name)) {
      setFormError(
        'Tenant name must be between 4-50 characters, start and end with alphanumeric characters, and only contain lowercase alphanumeric characters or "-".',
      );
      setIsSubmitting(false);
      return;
    }

    try {
      const payload = {
        cloud: selectedCloud?.slug,
        region: selectedRegion?.slug,
        tenantName: tenant.name,
        tenantDescription: tenant.description,
      };

      const response = await createTenant(
        payload,
        selectedOrganisation.identity,
      );

      setSuccess('Tenant created successfully!');
      toast.success('Tenant created successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      setError('');
      onCreate(response);
      window.location.reload();
    } catch (err) {
      console.error('Error creating tenant:', err);
      if (err.response) {
        setError(
          err.response.data.message ||
            'An error occurred while creating the tenant.',
        );
        toast.error(
          err.response.data.message ||
            'An error occurred while creating the tenant.',
          { autoClose: 3000, position: 'top-center' },
        );
      } else if (err.request) {
        setError('No response received from the server.');
      } else {
        setError('An unexpected error occurred.');
      }
      setSuccess('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
      <div className="bg-white shadow-lg rounded-lg  max-w-sm p-1 relative lg:max-w-3xl dark:bg-navy-700">
        <div className="card-body p-4">
          {/* Organization Selector */}
          <div className="mb-4">
            <label className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white">
              Select Organization
            </label>
            <select
              className="w-full p-1 text-xs border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white"
              value={selectedOrganisation?.identity || ''}
              onChange={e =>
                setSelectedOrganisation(
                  organisations.find(org => org.identity === e.target.value),
                )
              }
            >
              {organisations.map(org => (
                <option key={org.identity} value={org.identity}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>

          {selectedOrganisation && (
            <div className="mb-4">
              <label className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white">
                Select Cloud Provider
              </label>
              <div className="flex flex-wrap gap-4">
                {clouds.map(cloud => (
                  <div
                    key={cloud.identity}
                    className={`cursor-pointer p-3 border rounded-lg flex items-center space-x-3 ${selectedCloud?.identity === cloud.identity ? 'border-blue-500 bg-gray-200' : 'border-gray-300 dark:bg-navy-700 dark:border-navy-500'}`}
                    onClick={() => handleCloudSelect(cloud)}
                  >
                    <CloudIcon
                      cloudProviderType={cloud.cloudProviderType}
                      className="w-6 h-6"
                    />
                    <span className="text-xs font-semibold dark:text-white">
                      {cloud.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Region Selection Dropdown */}
          {selectedCloud && (
            <div className="mb-4">
              <label
                className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white"
                htmlFor="region"
              >
                Select Region
              </label>
              <select
                id="region"
                className="w-full p-1 text-xs border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white"
                value={selectedRegion?.identity || ''}
                onChange={handleRegionChange}
                disabled={!selectedCloud}
              >
                <option value="" disabled>
                  Select a region
                </option>
                {selectedCloud.regions.map(region => (
                  <option key={region.identity} value={region.identity}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Tenant Details Form */}
          <div className="mt-6">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="w-full p-2 text-xs border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white"
                  id="name"
                  name="name"
                  type="text"
                  value={tenant.name}
                  onChange={handleInputChange}
                  placeholder="Enter tenant name here"
                  required
                />
                <div className="mt-2 text-xs text-gray-900 lg:text-sm dark:text-white">
                  <p>
                    {validateName(tenant.name) ? (
                      <span className="text-green-600 mr-2">✓</span>
                    ) : (
                      <span className="text-red-600 mr-2">x</span>
                    )}
                    Must have characters length between 4-50
                    <br />
                    {validateName(tenant.name) ? (
                      <span className="text-green-600 mr-2">✓</span>
                    ) : (
                      <span className="text-red-600 mr-2">x</span>
                    )}
                    Must begin and end with an alphanumeric character [a-z, 0-9]
                    <br />
                    {validateName(tenant.name) ? (
                      <span className="text-green-600 mr-2">✓</span>
                    ) : (
                      <span className="text-red-600 mr-2">x</span>
                    )}
                    Must contain only lowercase characters.
                    <br />
                    {validateName(tenant.name) ? (
                      <span className="text-green-600 mr-2">✓</span>
                    ) : (
                      <span className="text-red-600 mr-2">x</span>
                    )}
                    Must contain only alphanumeric characters [a-z, 0-9] or '-'
                  </p>
                </div>
              </div>

              <div className="mb-4">
                <label
                  className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white"
                  htmlFor="description"
                >
                  Description (Optional)
                </label>
                <textarea
                  className="w-full p-2 text-xs border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white resize-none"
                  id="description"
                  name="description"
                  value={tenant.description}
                  onChange={handleInputChange}
                  placeholder="Enter description here"
                />
              </div>
              {formError && <p className="text-red-600 text-sm">{formError}</p>}
              {error && <p className="text-red-600">{error}</p>}
              {success && <p className="text-green-600">{success}</p>}

              <div className="flex justify-end gap-3 mt-4">
                <button
                  type="button"
                  className="bg-white hover:bg-gray-800 text-gray-900 text-xs hover:text-white shadow-md font-semibold px-4 py-2 rounded-md lg:text-sm dark:bg-navy-600 dark:text-white dark:hover:bg-navy-700"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`${
                    isSubmitting
                      ? 'bg-gray-500 text-gray-800 cursor-not-allowed'
                      : 'bg-customBlue hover:bg-gray-900 hover:shadow-lg dark:bg-navy-600 dark:hover:bg-navy-700'
                  } text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm`}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Creating...' : 'Create Tenant'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTenant;
