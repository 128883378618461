import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateNamespaceModal from './components/namespaces/CreateNamespace';
import NamespacesList from './components/namespaces/NamespacesList';
import { getTenants } from 'services/api/tenantService';
import { getOrganisationMemberships } from 'services/api/organizationService';
import Loading from 'views/loading/Loading';
import { FaBuilding, FaCube, FaCloudUploadAlt } from 'react-icons/fa';
import Widget from 'components/widget/Widget';

const Namespaces = () => {
  const navigate = useNavigate();
  const { slug, tenName, nsName } = useParams();
  const [organizationId, setOrganizationId] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [namespaces, setNamespaces] = useState({});
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNamespace, setSelectedNamespace] = useState(null);
  const [organizationName, setOrganizationName] = useState([]);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();

        if (response && response.length > 0) {
          setOrganizations(response.map(org => org.organisation));
          setSelectedOrganization(response[0].organisation);
          setOrganizationId(response[0].organisation.identity);
          setOrganizationName(response[0].organisation.name);
        } else {
          console.error('Error: No organization data found.');
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    fetchOrganisationData();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      if (!selectedOrganization) {
        setTenants([]);
        return;
      }

      setLoading(true);
      try {
        const tenantData = await getTenants(selectedOrganization.identity);
        setTenants(tenantData);

        const namespacesData = {};
        tenantData.forEach(tenant => {
          namespacesData[tenant.identity] = tenant.namespaces;
        });
        setNamespaces(namespacesData);

        if (tenantData.length > 0) {
          setSelectedTenant(tenantData[0]);
        } else {
          setSelectedTenant(null);
        }
      } catch (error) {
        console.error('Failed to fetch tenants:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTenants();
  }, [selectedOrganization]);

  const handleCreateNamespace = async newNamespace => {
    if (!selectedTenant || !organizationId) return;

    const namespaceData = {
      ...newNamespace,
      identity: newNamespace.identity,
      createdAt: new Date().toISOString(),
    };

    setNamespaces(prevNamespaces => ({
      ...prevNamespaces,
      [selectedTenant.identity]: [
        ...(prevNamespaces[selectedTenant.identity] || []),
        namespaceData,
      ],
    }));
  };

  useEffect(() => {
    if (selectedOrganization) {
      setOrganizationId(selectedOrganization.identity);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedTenant) {
    }
  }, [selectedTenant]);

  const handleOrganizationSelection = e => {
    const orgId = e.target.value;
    const org = organizations.find(o => o.identity === orgId);

    if (org) {
      setSelectedOrganization(org);
      setOrganizationId(org.identity);
      navigate(
        `/org/namespaces/${org.slug}/${tenants.name}/${namespaces.name}`,
      );
    }
    setTenants([]);
    setSelectedTenant(null);
    setNamespaces({});
  };

  const handleTenantSelection = e => {
    if (tenants.length === 0) return;
    const tenantId = e.target.value;
    const tenant = tenants.find(tenant => tenant.identity === tenantId);
    setSelectedTenant(tenant);
    setSelectedNamespace(namespaces[tenant.identity]?.[0] || null);

    const tenantNamespaces = namespaces[tenantId] || [];
    const namespaceName =
      tenantNamespaces.length > 0 ? tenantNamespaces[0].name : '';
    navigate(
      `/org/namespaces/${selectedOrganization.slug}/${tenant.name}/${namespaceName}`,
    );

    if (tenantNamespaces.length > 0) {
      setSelectedNamespace(tenantNamespaces[0]);
    } else {
      setSelectedNamespace(null);
      navigate(`/org/namespaces/${selectedOrganization.slug}/${tenant.name}/`);
    }
  };

  const handleNamespaceUpdate = updatedNamespaceData => {
    setNamespaces(prevNamespaces => ({
      ...prevNamespaces,
      [selectedTenant.identity]: prevNamespaces[selectedTenant.identity].map(
        namespace =>
          namespace.identity === updatedNamespaceData.identity
            ? updatedNamespaceData
            : namespace,
      ),
    }));
  };

  const handleNamespaceSelection = namespace => {
    if (!namespace) return;
    setSelectedNamespace(namespace);
    navigate(
      `/org/namespaces/${selectedOrganization.slug}/${selectedTenant.name}/${namespace.name}`,
    );
  };

  const handleNavigateToCreateTenant = () => {
    navigate('/org/tenants');
  };

  useEffect(() => {
    if (slug && tenName && nsName) {
      const org = organizations.find(o => o.slug === slug);
      const tenant = tenants.find(t => t.name === tenName);

      if (org) {
        setSelectedOrganization(org);
      }

      if (tenant) {
        setSelectedTenant(tenant);

        const namespace = namespaces[tenant.identity]?.find(
          n => n.name === nsName,
        );
        if (namespace) {
          setSelectedNamespace(namespace);
        }
      }
    }
  }, [slug, tenName, organizations, tenants, nsName, namespaces]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="max-w-8xl mx-auto mt-12">
      {/* Organization Widget */}
      <div className="mt-10 grid grid-cols-1 gap-5 mb-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<FaBuilding className="h-7 w-7" />}
          title="Organization"
          subtitle={
            <select
              value={selectedOrganization?.identity || ''}
              onChange={handleOrganizationSelection}
              className="text-xs border mt-2 p-1 rounded-md focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
            >
              {organizations.map(org => (
                <option key={org.identity} value={org.identity}>
                  {org.name}
                </option>
              ))}
            </select>
          }
        />

        {selectedOrganization && tenants.length > 0 && (
          <Widget
            icon={<FaCloudUploadAlt className="h-7 w-7" />}
            title="Tenant"
            subtitle={
              tenants.length > 0 ? (
                <select
                  value={selectedTenant ? selectedTenant.identity : ''}
                  onChange={handleTenantSelection}
                  className="text-xs border p-1 mt-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
                >
                  {tenants.map(tenant => (
                    <option key={tenant.identity} value={tenant.identity}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="text-sm text-gray-500">No tenants available</p>
              )
            }
          />
        )}

        <Widget
          icon={<FaCube className="h-6 w-6" />}
          title="Namespace"
          subtitle={
            selectedTenant &&
            namespaces[selectedTenant.identity]?.length > 0 ? (
              <select
                value={selectedNamespace?.identity || ''}
                onChange={e => {
                  const namespaceId = e.target.value;
                  const namespace = namespaces[selectedTenant.identity].find(
                    ns => ns.identity === namespaceId,
                  );
                  handleNamespaceSelection(namespace);
                }}
                className="text-xs border p-1 mt-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
              >
                {namespaces[selectedTenant.identity].map(namespace => (
                  <option key={namespace.identity} value={namespace.identity}>
                    {namespace.name}
                  </option>
                ))}
              </select>
            ) : (
              <select
                value=""
                className="text-xs border p-1 mt-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
                disabled
              >
                <option value="" disabled>
                  No namespaces
                </option>
              </select>
            )
          }
        />
      </div>

      {selectedTenant ? (
        <>
          {namespaces[selectedTenant.identity] &&
          namespaces[selectedTenant.identity].length > 0 ? (
            <NamespacesList
              namespaces={namespaces[selectedTenant.identity]}
              selectedNamespace={selectedNamespace}
              namespaceId={
                selectedNamespace ? selectedNamespace.identity : null
              }
              organizationName={organizationName}
              tenantName={selectedTenant.name}
              onAddNamespace={() => setIsModalOpen(true)}
              onSelectNamespace={handleNamespaceSelection}
              organizationId={organizationId}
              tenantId={selectedTenant.identity}
              onDeleteNamespace={namespaceIdentity => {
                const updatedNamespaces = namespaces[
                  selectedTenant.identity
                ].filter(namespace => namespace.identity !== namespaceIdentity);
                setNamespaces(prevNamespaces => ({
                  ...prevNamespaces,
                  [selectedTenant.identity]: updatedNamespaces,
                }));
              }}
              onNamespaceUpdated={handleNamespaceUpdate}
            />
          ) : (
            <div className="mt-8 dark:text-white">
              <p>
                No namespaces available for this tenant. You can{' '}
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="text-indigo-600 hover:underline focus:outline-none dark:text-blue-400"
                >
                  create a new namespace
                </button>{' '}
                to proceed.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="dark:text-white">
          <p>
            No tenant data found. You can{' '}
            <button
              onClick={handleNavigateToCreateTenant}
              className="text-indigo-600 hover:underline focus:outline-none dark:text-blue-400"
            >
              create a new tenant
            </button>{' '}
            by visiting the tenant page.
          </p>
        </div>
      )}

      {/* Modals */}
      <CreateNamespaceModal
        organizationId={organizationId}
        tenantId={selectedTenant?.identity}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateNamespace}
      />
    </div>
  );
};

export default Namespaces;
