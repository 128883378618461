import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import WidgetCard from 'components/widget/WidgetCard';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { getOrganisationMember } from 'services/api/organizationService';
import RoleBadge from 'utilities/RoleBadge';

const MemberCard = () => {
  const [memberships, setMemberships] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await getOrganisationMemberships();
        setMemberships(response);
        if (response.length > 0) {
          setSelectedOrgId(response[0].organisation.identity);
        }
      } catch (error) {
        console.error('Failed to fetch organization memberships', error);
      }
    };

    getOrganization();
  }, []);

  useEffect(() => {
    if (selectedOrgId) {
      const getMember = async () => {
        try {
          const response = await getOrganisationMember(selectedOrgId);
          setMembers(response);
        } catch (error) {
          console.error('Failed to fetch organization members', error);
        }
      };

      getMember();
    }
  }, [selectedOrgId]);

  const selectedMembership = memberships.find(
    membership => membership.organisation.identity === selectedOrgId,
  );

  return (
    <WidgetCard
      title={
        <div className="flex justify-between">
          <h1 className="">Members</h1>
          {/* <button className="flex flex-row text-gray-700">
            <FaEnvelope className="mr-2 mt-1" />
            Invite member
          </button> */}
        </div>
      }
    >
      {/* Organization Selector */}
      <div className="mb-4">
        <label
          htmlFor="organization-select"
          className="block text-xs font-medium text-gray-700 dark:text-gray-300"
        >
          Select Organization
        </label>
        <select
          id="organization-select"
          value={selectedOrgId || ''}
          onChange={e => setSelectedOrgId(e.target.value)}
          className="mt-1 block w-full p-1 text-xs border border-gray-300 rounded-md dark:border-navy-600 dark:bg-navy-700 dark:text-white focus:outline-none focus:ring focus:ring-blue-500"
        >
          {memberships.map(membership => (
            <option
              key={membership.organisation.identity}
              value={membership.organisation.identity}
            >
              {membership.organisation.name}
            </option>
          ))}
        </select>
      </div>

      {/* Members List */}
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-200 divide-y divide-gray-300 dark:border-navy-600 dark:divide-navy-600">
          <thead className=" dark:bg-navy-800">
            <tr>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-600 dark:text-gray-400 uppercase tracking-wider">
                Member Name
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-600 dark:text-gray-400 uppercase tracking-wider">
                Role
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-navy-700 divide-y divide-gray-300 dark:divide-navy-600">
            {members.length > 0 ? (
              members.map((mem, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 dark:hover:bg-navy-600"
                >
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-blue-600 cursor-pointer dark:text-blue-300">
                    <Link
                      to={`./org/organization-members/${selectedMembership?.organisation.slug}`}
                    >
                      {mem.user.displayName || `Member ${index + 1}`}
                    </Link>
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap">
                    <RoleBadge role={mem.role} size="sm" />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="2"
                  className="px-4 py-2 text-xs text-center text-gray-500 dark:text-gray-400"
                >
                  No members available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </WidgetCard>
  );
};

export default MemberCard;
