import { useEffect, useState } from 'react';
import Dropdown from 'components/dropdown';
import InfoDropdown from './InfoMenu';
import KeycloakService from 'services/KeycloakService';
import Notifications from './Notifications';
import RenderWhenAuthenticated from 'utilities/RenderWhenAuthenticated';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import avatar from 'assets/img/avatars/avatar4.png';

const Navbar = props => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);
  const [userName, setUserName] = useState('User');
  const [profilePicture, setProfilePicture] = useState(avatar);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    if (KeycloakService.isLoggedIn()) {
      const tokenParsed = KeycloakService.keycloak.tokenParsed;
      setUserName(tokenParsed.preferred_username || 'User');

      KeycloakService.getUserProfile().then(profile => {
        setProfilePicture(
          profile.googleProfilePhotoUrl || profile.githubAvatarUrl || avatar,
        );
      });
    } else {
      KeycloakService.doLogin();
    }
  }, []);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await KeycloakService.doLogout();
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              /{' '}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-lg mt-2 mb-2 capitalize text-navy-700 lg:text-[33px] dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* Notification Dropdown */}
        <Notifications />
        {/* Info Dropdown */}
        <InfoDropdown />
        {/* Dark Mode */}
        <button
          type="button"
          aria-label={darkmode ? 'Switch to light mode' : 'Switch to dark mode'}
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </button>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 cursor-pointer rounded-full"
              src={profilePicture}
              alt="User Avatar"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {userName}
                  </p>
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
              <div className="flex flex-col p-2">
                {/* RenderWhenAuthenticated for Profile Settings and Logout */}
                <RenderWhenAuthenticated>
                  <a
                    href="/auth/settings"
                    className="text-sm p-2 text-gray-800 hover:bg-gray-50 dark:text-white  hover:dark:text-white dark:hover:bg-navy-600"
                  >
                    Profile Settings
                  </a>
                  <a
                    href=" "
                    className="mt-2 p-2 text-sm text-gray-800 hover:bg-gray-50 dark:text-white hover:dark:text-white dark:hover:bg-navy-600"
                  >
                    Newsletter Settings
                  </a>
                  <button
                    onClick={handleLogout}
                    disabled={isLoggingOut}
                    className={`mt-2 p-2 text-sm text-left font-medium text-red-500 rounded-lg hover:bg-gray-50 transition dark:hover:bg-navy-600 ${
                      isLoggingOut ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isLoggingOut ? 'Logging Out...' : 'Log Out'}
                  </button>
                </RenderWhenAuthenticated>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
