import React, { useState } from 'react';
import { createOrganisation } from 'services/api/organizationService';
import { toast } from 'react-toastify';

function CreateOrganization({ onClose = () => {}, onSuccess = () => {} }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateName = name => {
    const regex = /^[a-z0-9][a-z0-9-]{2,48}[a-z0-9]$/;
    return regex.test(name);
  };

  const handleCreate = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateName(name)) {
      setFormError(
        'Organization name must be between 4-50 characters, start and end with alphanumeric characters, and only contain lowercase alphanumeric characters or "-".',
      );
      return;
    }

    try {
      const createOrganisationData = {
        name,
        description: description || undefined,
      };

      const response = await createOrganisation(createOrganisationData);

      if (response) {
        toast.success('Organization created successfully', {
          autoClose: 3000,
          position: 'top-center',
        });
        setName('');
        setDescription('');
        setFormError(null);
        setTimeout(() => {
          window.location.reload();
          onSuccess();
          onClose();
        }, 2000);
      } else {
        setError('Failed to create organization.');
        toast.error('Failed to create organization', {
          autoClose: 3000,
          position: 'top-center',
        });
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message || 'An error occurred');
      } else if (err.request) {
        setError('No response received from the server');
      } else {
        setError('An unexpected error occurred');
      }
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50  dark:bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full lg:max-w-lg dark:bg-navy-700">
        <h1 className="text-sm font-bold mb-4 text-center lg:text-lg dark:text-white">
          Add New Organization
        </h1>
        <form onSubmit={handleCreate} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-900 mb-2 dark:text-white">
              Organization Name
            </label>
            <input
              type="text"
              placeholder="my-new-org"
              value={name}
              onChange={e => setName(e.target.value)}
              required
              className="mt-1 text-xs block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:text-white"
            />
            <div className="flex flex-col gap-1 text-left">
              <p className="mt-2 text-xs text-gray-900 mb-4 lg:text-sm dark:text-white">
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must have characters length between 4-50
                <br />
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must begin and end with an alphanumeric character [a-z, 0-9]
                <br />
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must contain only lowercase characters.
                <br />
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must contain only alphanumeric characters [a-z, 0-9] or '-'
              </p>
            </div>
          </div>

          {/* New Description Input */}
          <div>
            <label className="block text-sm font-medium text-gray-900 mb-2 dark:text-white">
              Description (optional, max 150 characters)
            </label>
            <textarea
              placeholder="A brief description of your organization"
              value={description}
              onChange={e => setDescription(e.target.value)}
              style={{ resize: 'none' }}
              maxLength={150}
              className="mt-1 block text-xs w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:text-white"
            />
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
              {description.length}/150 characters
            </p>
          </div>

          {formError && <p className="text-red-600 text-sm">{formError}</p>}
          {error && <p className="text-red-600 text-sm">{error}</p>}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={handleCancel}
              className="bg-white hover:bg-gray-800 text-xs hover:text-white font-semibold text-gray-900 px-4 py-2 rounded-lg shadow-md flex items-center lg:text-sm dark:bg-navy-600 dark:text-white  dark:hover:bg-navy-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`${
                isSubmitting
                  ? 'bg-gray-500 text-gray-800 cursor-not-allowed'
                  : 'bg-customBlue hover:bg-gray-900 hover:shadow-lg dark:bg-navy-600 dark:hover:bg-navy-700'
              } text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateOrganization;
