import React, { useState, useEffect } from 'react';
import CreateTenant from './components/tenant/CreateTenant';
import TenantsView from './components/tenant/TenantView';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { getTenants } from 'services/api/tenantService';
import {
  FaBuilding,
  FaCloudUploadAlt,
  FaSitemap,
  FaCloud,
} from 'react-icons/fa';
import Widget from 'components/widget/Widget';
import { useParams, useNavigate } from 'react-router-dom';

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [showCreateTenant, setShowCreateTenant] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const { slug, tenName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();

        if (response && response.length > 0) {
          setOrganisations(response.map(org => org.organisation));
          setSelectedOrganisation('ALL_ORGANIZATIONS');
        } else {
          console.error('Error: No organization data found.');
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
        setError('Failed to load organization data');
      }
    };

    fetchOrganisationData();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        setTenants([]);

        if (selectedOrganisation === 'ALL_ORGANIZATIONS') {
          let allTenants = [];
          for (let org of organisations) {
            const tenantsData = await getTenants(org.identity);
            const tenantsWithOrgId = tenantsData.map(tenant => ({
              ...tenant,
              organizationId: org.identity,
            }));
            allTenants = [...allTenants, ...tenantsWithOrgId];
          }
          setTenants(allTenants);
        } else {
          const tenantsData = await getTenants(selectedOrganisation.identity);
          setTenants(
            tenantsData.map(tenant => ({
              ...tenant,
              organizationId: selectedOrganisation.identity,
            })),
          );
        }
      } catch (err) {
        console.error('Error fetching tenants data:', err);
        setError('Failed to load tenants');
      }
    };

    if (selectedOrganisation) {
      fetchTenants();
    }
  }, [selectedOrganisation, organisations]);

  const handleCreateTenant = newTenant => {
    setTenants(prevTenants => [...prevTenants, newTenant]);
    setShowCreateTenant(false);
  };

  const handleOrganisationChange = e => {
    const orgId = e.target.value;
    if (orgId === 'ALL_ORGANIZATIONS') {
      setSelectedOrganisation('ALL_ORGANIZATIONS');
    } else {
      const org = organisations.find(o => o.slug === orgId);
      setSelectedOrganisation(org);
    }
  };

  const handleTenantChange = e => {
    const tenantId = e.target.value;
    const tenant = tenants.find(t => t.identity === tenantId);
    setSelectedTenant(tenant);

    if (tenant) {
      const org = organisations.find(o => o.identity === tenant.organizationId);
      setSelectedOrganisation(org);

      navigate(`/org/tenants/${selectedOrganisation.slug}/${tenant.name}`);
    }
  };

  const handleTenantClick = tenant => {
    if (tenant) {
      setSelectedTenant(tenant);
    } else {
      setSelectedTenant(null);
    }
  };

  useEffect(() => {
    if (selectedTenant) {
      const org = organisations.find(
        o => o.identity === selectedTenant.organizationId,
      );
      if (org) {
        setSelectedOrganisation(org);
        navigate(`/org/tenants/${org.slug}/${selectedTenant.name}`);
      } else {
        console.error('Organization not found for tenant:', selectedTenant);
      }
    }
  }, [selectedTenant, organisations, navigate]);

  useEffect(() => {
    if (slug && tenName) {
      const org = organisations.find(o => o.slug === slug);
      const tenant = tenants.find(t => t.name === tenName);

      if (org) {
        setSelectedOrganisation(org);
      }

      if (tenant) {
        setSelectedTenant(tenant);
      }
    }
  }, [slug, tenName, organisations, tenants]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-8xl mx-auto mt-12">
      {/* Organization Widget */}
      <div className="mt-10 grid grid-cols-1 gap-5 mb-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<FaBuilding className="h-7 w-7" />}
          title={'Organization'}
          subtitle={
            <select
              id="organisationDropdown"
              className="w-full mt-2 p-1 text-xs border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
              onChange={handleOrganisationChange}
              value={
                selectedOrganisation === 'ALL_ORGANIZATIONS'
                  ? 'ALL_ORGANIZATIONS'
                  : selectedOrganisation?.slug || ''
              }
            >
              <option value="ALL_ORGANIZATIONS">All Organizations</option>
              {organisations.map(org => (
                <option key={org.slug} value={org.slug}>
                  {org.name}
                </option>
              ))}
            </select>
          }
        />

        {selectedOrganisation !== 'ALL_ORGANIZATIONS' &&
          selectedOrganisation && (
            <Widget
              icon={<FaCloudUploadAlt className="h-6 w-6" />}
              title={'Tenant'}
              subtitle={
                <select
                  id="tenantDropdown"
                  className="w-full mt-2 p-1 text-xs border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
                  onChange={handleTenantChange}
                  value={selectedTenant?.identity || ''}
                >
                  {tenants.length > 0 ? (
                    tenants.map(tenant => (
                      <option key={tenant.identity} value={tenant.identity}>
                        {tenant.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No tenant
                    </option>
                  )}
                </select>
              }
            />
          )}

        {selectedOrganisation !== 'ALL_ORGANIZATIONS' &&
          selectedOrganisation && (
            <Widget
              icon={<FaCloud className="h-7 w-7" />}
              title={'Number of Tenants'}
              subtitle={
                tenants.filter(
                  tenant =>
                    tenant.organizationId === selectedOrganisation.identity,
                ).length || '0'
              }
            />
          )}

        {selectedOrganisation === 'ALL_ORGANIZATIONS' && (
          <>
            <Widget
              icon={<FaSitemap className="h-6 w-6" />}
              title={'Number of Org'}
              subtitle={organisations.length || 'N/A'}
            />
            <Widget
              icon={<FaCloud className="h-7 w-7" />}
              title={'Number of Tenants'}
              subtitle={tenants.length || 'N/A'}
            />
          </>
        )}
      </div>

      {/* Tenants List or Create Tenant Modal */}
      <div className="mt-5">
        {tenants && tenants.length > 0 ? (
          <TenantsView
            organizationId={selectedOrganisation?.slug || slug}
            tenants={tenants || tenName}
            selectedTenant={selectedTenant}
            onSelectTenant={handleTenantClick}
          />
        ) : showCreateTenant ? (
          <CreateTenant
            onCreate={handleCreateTenant}
            onCancel={() => setShowCreateTenant(false)}
          />
        ) : (
          <div className=" dark:text-white">
            <p>
              No tenant data found. You can{' '}
              <button
                onClick={() => setShowCreateTenant(true)}
                className="text-indigo-600 hover:underline focus:outline-none dark:text-blue-400"
              >
                create a new tenant
              </button>{' '}
              to proceed.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tenants;
