import React, { useState, useEffect, useRef } from 'react';
import {
  FaHistory,
  FaKey,
  FaTable,
  FaFileCode,
  FaCube,
  FaFileUpload,
} from 'react-icons/fa';
import { FiAlignJustify } from 'react-icons/fi';
import { HiX } from 'react-icons/hi';
import KubeconfigFetcher from './KubeconfigFetcher';
import Loading from 'views/loading/Loading';
import TenantSummary from './Tenantsummary';
import NamespacesListInTenant from './Namespace';
import { getTenant } from 'services/api/tenantService';
import ApplyManifestInTenant from '../ApplyManifestInTenant';
import CreateNamespaceModal from 'views/client/namespaces/components/namespaces/CreateNamespace';

const KubeTenantView = ({ organizationId, tenantId, refreshTenants }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [tenantName, setTenantName] = useState('');
  const [namespaces, setNamespaces] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [activeSection, setActiveSection] = useState('summary');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pollingIntervalRef = useRef(null);

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const response = await getTenant(tenantId, organizationId);
        setTenantName(response.data.name);
        setOrganizationName(response.data.organisation.name);
        if (response && response.data) {
          setTenant(response.data);

          const namespacesData = response.data.namespaces;
          setNamespaces(namespacesData);
        } else {
          setError('No tenant data available.');
        }
      } catch (err) {
        setError(`Failed to fetch tenant data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTenant();
  }, [organizationId, tenantId]);

  useEffect(() => {
    if (tenant && tenant?.status === 'provisioning') {
      pollingIntervalRef.current = setInterval(async () => {
        try {
          const response = await getTenant(tenantId, organizationId);
          if (response && response.data) {
            setTenant(response.data);
          }
        } catch (error) {
          console.error(`Polling error: ${error.message}`);
        }
      }, 60000);
    }

    return () => clearInterval(pollingIntervalRef.current);
  }, [organizationId, tenantId, tenant?.status, tenant]);

  const handleSectionClick = section => {
    setActiveSection(section);
    setMenuOpen(false);
  };

  const handleCreateNamespace = async newNamespace => {
    if (!tenantId || !organizationId) return;

    const namespaceData = {
      ...newNamespace,
      identity: newNamespace.identity,
      createdAt: new Date().toISOString(),
    };

    setNamespaces(prevNamespaces => [...prevNamespaces, namespaceData]);
  };

  const handleAddNamespace = () => {
    setIsModalOpen(true);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'summary':
        return (
          <TenantSummary tenant={tenant} refreshTenants={refreshTenants} />
        );
      case 'kubeConfig':
        return (
          <KubeconfigFetcher
            tenantId={tenantId}
            organizationId={organizationId}
            organizationName={organizationName}
            tenantName={tenantName}
          />
        );

      case 'namespaces':
        return (
          <NamespacesListInTenant
            tenantId={tenantId}
            organizationId={organizationId}
            namespaces={namespaces}
            onAddNamespace={handleAddNamespace}
          />
        );

      case 'applyManifest':
        return (
          <ApplyManifestInTenant
            tenantId={tenantId}
            organizationId={organizationId}
            namespaces={namespaces}
          />
        );

      case 'audit':
        return <div className="dark:text-white">Audit content goes here.</div>;
      case 'security':
        return (
          <div className="dark:text-white">Security content goes here.</div>
        );
      default:
        return null;
    }
  };

  if (loading || !tenant) return <Loading />;
  if (error) return <p className="text-red-600">{error}</p>;
  if (!tenant)
    return (
      <p className="text-center text-gray-600">No tenant data available.</p>
    );

  return (
    <div className="flex flex-col">
      {/* Hamburger Button */}
      <div className="md:hidden flex justify-between border-b items-center p-4 mt-3 dark:border-gray-700">
        <h1 className="text-sm dark:text-white">{tenant?.name}</h1>
        <button
          className="text-xl text-gray-600"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <HiX className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <FiAlignJustify />
          )}
        </button>
      </div>
      <div className="flex flex-col">
        {/* Horizontal Menu */}
        <nav
          className={`${
            menuOpen ? 'block' : 'hidden'
          } md:flex md:flex-row space-x-3 border-b-2 mb-4 dark:border-gray-700`}
        >
          <div
            className={`flex items-center cursor-pointer py-2 px-7 lg:px-4 ${
              activeSection === 'summary'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('summary')}
          >
            <FaTable className="mr-2" />
            <span>Summary</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'kubeConfig'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('kubeConfig')}
          >
            <FaFileCode className="mr-2" />
            <span>KubeConfig</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'namespaces'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('namespaces')}
          >
            <FaCube className="mr-2" />
            <span>Namespaces</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'applyManifest'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('applyManifest')}
          >
            <FaFileUpload className="mr-2" />
            <span>Apply manifest</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'audit'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('audit')}
          >
            <FaHistory className="mr-2" />
            <span>Audit</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'security'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('security')}
          >
            <FaKey className="mr-2" />
            <span>Security</span>
          </div>
        </nav>

        {/* Content Area */}
        <div className="flex-1 p-6">
          {renderContent()}
          <div className="mt-4"></div>
        </div>
      </div>
      <CreateNamespaceModal
        organizationId={organizationId}
        tenantId={tenantId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateNamespace}
      />
    </div>
  );
};

export default KubeTenantView;
