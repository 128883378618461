import apiClient from '../apiService';

export const getOrganisationMemberships = async () => {
  try {
    const response = await apiClient.get('/v1/me/organisation-memberships');
    return response.data;
  } catch (err) {
    console.error('Error getting organisations:', err);
    return null;
  }
};

export const createOrganisation = async organisation => {
  try {
    const response = await apiClient.post('/v1/organizations/', organisation);
    return response.data;
  } catch (err) {
    console.error('Error creating organisation:', err);
    return null;
  }
};

export const getOrganisation = async slug => {
  try {
    const response = await apiClient.get(`/v1/organizations/${slug}/`);
    return response.data;
  } catch (err) {
    console.error('Error getting organisation:', err);
    return null;
  }
};

export const getOrganisationMember = async identity => {
  try {
    const response = await apiClient.get(
      `/v1/me/organisation-memberships/${identity}`,
    );
    return response.data;
  } catch (err) {
    console.error('Error getting organisation members:', err);
    return null;
  }
};

export const updateOrganisation = async (slug, organisation) => {
  try {
    const response = await apiClient.put(
      `/v1/organizations/${slug}/`,
      organisation,
    );
    return response.data;
  } catch (err) {
    console.error('Error updating organisation:', err);
    return null;
  }
};

export const deleteOrganisation = async identity => {
  try {
    const response = await apiClient.delete(`/v1/organizations/${identity}`);
    if (response.status === 204) {
      return response;
    } else if (response.status === 409) {
      throw new Error('Organization already exists');
    } else {
      throw new Error('Failed to delete organization');
    }
  } catch (err) {
    console.error('Error deleting organisation:', err);
    return null;
  }
};

export const getOrganisationInvites = async () => {
  try {
    const response = await apiClient.get('/v1/me/organisation-invites');
    return response.data;
  } catch (err) {
    console.error('Error getting organisation invites:', err);
    return null;
  }
};

export const getMemberCount = async organizationId => {
  try {
    const response = await apiClient.get(`/v1/organizations/${organizationId}`);
    return response.data.member_count;
  } catch (error) {
    console.error('Error fetching organization details:', error);
  }
};

