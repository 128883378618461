import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import yaml from 'js-yaml';
import { sendYaml } from 'services/api/namespaceService';
import nginxYaml from 'utilities/nginxYaml';

const ApplyManifestInTenant = ({ organizationId, tenantId, namespaces }) => {
  const [yamlContent, setYamlContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (namespaces && namespaces.length > 0) {
      setSelectedNamespace(namespaces[0].identity);
    }
  }, [namespaces]);

  const convertYamlToJson = yamlContent => {
    try {
      return yaml.loadAll(yamlContent);
    } catch (e) {
      throw new Error('Invalid YAML format');
    }
  };

  const handleYamlChange = event => {
    setYamlContent(event.target.value);
  };

  const handleNginxClick = () => {
    setYamlContent(nginxYaml);
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      try {
        const text = await file.text();
        setYamlContent(text);
      } catch (err) {
        console.error('Failed to read file:', err);
        toast.error('Failed to read file. Please try again.', {
          autoClose: 3000,
          position: 'top-center',
        });
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const documents = convertYamlToJson(yamlContent);

      for (const doc of documents) {
        if (doc) {
          const response = await sendYaml(
            organizationId,
            tenantId,
            selectedNamespace,
            doc,
          );

          if (response === 'created') {
            toast.success('Document sent successfully!', {
              autoClose: 3000,
              position: 'top-center',
            });
          } else {
            throw new Error('Failed to send document: ');
          }
        }
      }
      setYamlContent('');
      setFileName('');
    } catch (err) {
      const errorMessage =
        err.message || 'Failed to send YAML. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage, {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNamespaceSelect = event => {
    setSelectedNamespace(event.target.value);
  };

  return (
    <div>
      {/* Select Dropdown for Namespaces */}
      <div className="mb-4">
        <label htmlFor="namespace-select" className="block text-gray-700">
          Select Namespace:
        </label>
        <select
          id="namespace-select"
          value={selectedNamespace}
          onChange={handleNamespaceSelect}
          className="mt-2 block w-full p-1 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[620px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
        >
          {namespaces.length === 0 ? (
            <option value="" disabled>
              No namespaces available
            </option>
          ) : (
            namespaces.map(namespace => (
              <option key={namespace.identity} value={namespace.identity}>
                {namespace.name}
              </option>
            ))
          )}
        </select>
      </div>

      <div className="mt-6 dark:bg-navy-700 dark:text-white">
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label
              htmlFor="yamlInput"
              className="block text-sm font-semibold mb-2 text-gray-700 dark:text-white"
            >
              Paste your YAML content here or upload a YAML file
            </label>
            <textarea
              id="yamlInput"
              rows="10"
              value={yamlContent}
              onChange={handleYamlChange}
              className="w-full p-3 border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white dark:border-gray-700"
              placeholder="Enter YAML content here..."
              style={{ resize: 'none' }}
              required
            />
          </div>
          <p className="text-sm font-semibold mb-2 text-gray-700 dark:text-white">
            {' '}
            Or upload a YAML file
          </p>
          {/* File Upload and Buttons Section */}
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <input
                type="file"
                id="fileInput"
                accept=".yaml,.yml"
                onChange={handleFileUpload}
                className="hidden"
              />

              <button
                type="button"
                onClick={() => document.getElementById('fileInput').click()}
                className="bg-customBlue text-white text-xs font-semibold hover:bg-gray-800 py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700"
              >
                Choose File
              </button>
              {fileName && (
                <span className="text-sm text-gray-700 dark:text-white">
                  {fileName}
                </span>
              )}

              <button
                type="submit"
                disabled={loading}
                className={`bg-customBlue hover:bg-gray-800 text-white text-xs font-semibold py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {loading ? 'Applying...' : 'Apply'}
              </button>
            </div>

            <button
              type="button"
              onClick={handleNginxClick}
              className="bg-white hover:bg-gray-800 text-green-600 text-xs border border-gray-300 font-semibold py-2 px-4 shadow-md rounded-md dark:bg-navy-600 dark:hover:bg-navy-700 dark:border-gray-900 ml-auto"
            >
              Use NGINX
            </button>
          </div>

          {error && <p className="text-red-600 mb-4">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default ApplyManifestInTenant;
