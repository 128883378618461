import React, { useState } from 'react';
import { inviteMember } from 'services/api/inviteService';
import { FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const InviteMember = ({ onCancel, slug, organizationName }) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Member');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await inviteMember(slug, email, role);
      toast.success('User invited successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      setEmail('');
      setRole('Member');
      onCancel();
    } catch (err) {
      setMessage(err.message || 'Error inviting user.');
      toast.error(err.message || 'Error inviting user.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-1">
      <div className="pb-4 mb-2  w-full ">
        <h2 className="text-lg font-bold mb-5 w-full lg:text-xl dark:text-white">
          Invite User
        </h2>
        <div className="flex items-center dark:border-gray-700">
          <div className="flex flex-row items-center">
            <FaUser className="w-4 h-4 text-gray-500 mr-2" />
            <div className="mt-2">
              {organizationName ? (
                <p className="text-gray-900 text-xs  text-center lg:text-md dark:text-white">
                  Invite a new user to{' '}
                  <span className="text-customBlue text-sm font-bold dark:text-blue-400">
                    {organizationName}
                  </span>{' '}
                  organization
                </p>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {message && (
          <p
            className={`text-center mb-4 ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}
          >
            {message}
          </p>
        )}
        <div className="flex flex-col gap-2 lg:flex lg:flex-row">
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              Email:
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter email here"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              className="w-full px-3 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-[300px] dark:bg-navy-700 dark:text-white dark:border-gray-700"
            />
          </div>
          <div className="mb-1">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="role"
            >
              Role:
            </label>
            <select
              id="role"
              value={role}
              onChange={e => setRole(e.target.value)}
              className="w-full px-3 py-1 text-xs border border-gray-300 mb-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-[300px] dark:bg-navy-700 dark:text-white dark:border-gray-700"
            >
              <option value="Admin">Admin</option>
              <option value="Member">Member</option>
              <option value="Viewer">Viewer</option>
            </select>
          </div>
        </div>
        <div className="flex justify-start space-x-4">
          <button
            type="button"
            onClick={onCancel}
            className="bg-white text-gray-900 text-xs px-4 py-2 rounded-md shadow-md font-semibold hover:bg-gray-800 hover:text-white hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out  dark:bg-navy-600 dark:hover:bg-navy-700 dark:text-white"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700 ${
              loading ? 'bg-blue-300' : 'bg-blue-500 hover:bg-blue-700'
            }`}
          >
            {loading ? 'Inviting...' : 'Invite Member'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InviteMember;
