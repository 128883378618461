import React, { useState, useEffect } from 'react';
import {
  FaHistory,
  FaTable,
  FaCubes,
  FaFileUpload,
  FaGlobe,
} from 'react-icons/fa';
import { FiAlignJustify } from 'react-icons/fi';
import { HiX } from 'react-icons/hi';
import ApplyManifest from './ApplyManifest';
import Loading from 'views/loading/Loading';
import NamespaceSummary from './NamespaceSummary';
import Resources from './resources/Resources';
import ExposeApp from '../ExposeApp';

const NamespaceView = ({
  organizationId,
  tenantId,
  namespaceId,
  organizationName,
  tenantName,
  namespace,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(!namespace);
  const [activeSection, setActiveSection] = useState('summary');

  useEffect(() => {
    if (namespace) {
      setLoading(false);
    } else {
      setLoading(true);
      setError(true);
    }
  }, [namespace]);

  const handleSectionClick = section => {
    setActiveSection(section);
    setMenuOpen(false);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'summary':
        return (
          <NamespaceSummary
            namespace={namespace}
            tenant={tenantId}
            organizationId={organizationId}
            namespaceId={namespaceId}
          />
        );
      case 'applyManifest':
        return (
          <ApplyManifest
            tenantId={tenantId}
            organizationId={organizationId}
            namespaceId={namespaceId}
          />
        );

      case 'resources':
        return (
          <Resources
            tenantId={tenantId}
            organizationId={organizationId}
            namespaceId={namespaceId}
            organizationName={organizationName}
            tenantName={tenantName}
          />
        );

      case 'exposeApp':
        return (
          <ExposeApp
            namespace={namespace}
            tenantId={tenantId}
            organizationId={organizationId}
            namespaceId={namespaceId}
            organizationName={organizationName}
            tenantName={tenantName}
          />
        );

      case 'audit':
        return <div className="dark:text-white">Audit content goes here.</div>;
      default:
        return null;
    }
  };

  if (loading) return <Loading />;
  if (error) return <p className="text-red-600">{error}</p>;
  if (!namespace)
    return (
      <p className="text-center text-gray-600">No namespace data available.</p>
    );

  return (
    <div className="flex flex-col">
      {/* Hamburger Button */}
      <div className="md:hidden flex justify-between border-b items-center p-4 mt-3 dark:border-gray-700">
        <h1 className="text-sm dark:text-white">{namespace?.name}</h1>
        <button
          className="text-xl text-gray-600"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <HiX className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <FiAlignJustify />
          )}
        </button>
      </div>
      <div className="flex flex-col">
        {/* Horizontal Menu */}
        <nav
          className={`${
            menuOpen ? 'block' : 'hidden'
          } md:flex md:flex-row space-x-3 border-b-2 mb-4 dark:border-gray-700`}
        >
          <div
            className={`flex items-center cursor-pointer py-2 px-7 lg:px-4 ${
              activeSection === 'summary'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('summary')}
          >
            <FaTable className="mr-2" />
            <span>Summary</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'applyManifest'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('applyManifest')}
          >
            <FaFileUpload className="mr-2" />
            <span>Apply manifest</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'resources'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('resources')}
          >
            <FaCubes className="mr-2" />
            <span>Resources</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'exposeApp'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('exposeApp')}
          >
            <FaGlobe className="mr-2" />
            <span>Expose App</span>
          </div>
          <div
            className={`flex items-center cursor-pointer py-2 px-4 ${
              activeSection === 'audit'
                ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                : 'text-gray-700 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
            onClick={() => handleSectionClick('audit')}
          >
            <FaHistory className="mr-2" />
            <span>Audit</span>
          </div>
        </nav>

        {/* Content Area */}
        <div className="flex-1 p-6">
          {renderContent()}
          <div className="mt-4"></div>
        </div>
      </div>
    </div>
  );
};

export default NamespaceView;
