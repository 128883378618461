import React, { useEffect, useState } from 'react';
import { getPods, getPodDetails } from 'services/api/resourcesService';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const Pods = ({ organizationId, tenantId, namespaceId, onPodSelect }) => {
  const [pods, setPods] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPods = async () => {
      setLoading(true);
      try {
        const resourceData = await getPods(
          organizationId,
          tenantId,
          namespaceId,
        );

        const podsWithDetails = await Promise.all(
          resourceData.map(async pod => {
            try {
              const details = await getPodDetails(
                organizationId,
                tenantId,
                namespaceId,
                pod?.metadata?.name || 'default',
              );
              return { ...pod, details };
            } catch (err) {
              console.error(
                `Failed to fetch details for pod ${pod.metadata.name}:`,
                err,
              );
              toast.error(
                `Failed to fetch details for pod ${pod.metadata.name}: ${err.message}`,
              );
              return { ...pod, details: null };
            }
          }),
        );

        setPods(podsWithDetails);
      } catch (err) {
        toast.error('Failed to fetch Pods.', {
          autoClose: 3000,
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (namespaceId) {
      fetchPods();
    }
  }, [organizationId, tenantId, namespaceId]);

  if (loading) return <Loading />;

  const handlePodSelect = podName => {
    const selectedPod = pods.find(pod => pod.name === podName);

    if (selectedPod && selectedPod.details) {
      const podDetailsArray = selectedPod.details.items;

      const matchingPod = podDetailsArray.find(
        detailPod => detailPod.metadata.name === podName,
      );

      if (matchingPod) {
        onPodSelect(matchingPod);
      } else {
        toast.error(`No detailed information found for pod name: ${podName}`);
      }
    } else {
      toast.error(`Pod details not found for pod name: ${podName}`);
    }
  };

  return (
    <div>
      <h4 className="text-sm font-semibold mb-4 lg:text-lg dark:text-white">
        Pods
      </h4>
      {pods && pods.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-auto dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Pod Name
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  CPU
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Memory
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              {pods.map((pod, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                  onClick={() => handlePodSelect(pod.name)}
                >
                  <td className="px-6 py-2 whitespace-nowrap text-sm cursor-pointer text-blue-600 dark:text-blue-300">
                    {pod.name}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {pod.status}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {pod.cpu || 'N/A'}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {pod.memory || 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-gray-500">No pods available.</div>
      )}
    </div>
  );
};

export default Pods;
