import React from 'react';

const TenantStatus = ({ status, size = 'md' }) => {
  const getStatusBadgeClass = (status) => {
    switch (status) {
      // case 'creating': return 'bg-green-100 text-green-600';
      // case 'provisioning': return 'bg-purple-100 text-purple-600';
      // case 'updating': return 'bg-blue-100 text-blue-600';
      // case 'deleting': return 'bg-red-100 text-red-700';
      // case 'deleted': return 'bg-red-100 text-red-700';
      // case 'failed': return 'bg-red-100 text-red-700';
      case 'ready': return 'bg-green-100 text-green-700 dark:bg-green-700 dark:text-white';
      default: return 'bg-green-100 text-green-700 dark:bg-green-700 dark:text-white';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      // case 'creating': return 'Creating ...';
      // case 'provisioning': return 'Provisioning ...';
      // case 'updating': return 'Updating ...';
      // case 'deleting': return 'Deleting';
      // case 'deleted': return 'Deleted';
      // case 'failed': return 'Failed';
      case 'ready': return 'Ready';
      default: return 'Ready';
    }
  };

  const getSizeClass = (size) => {
    switch (size) {
      case 'sm': return 'py-0 px-1 text-[10px]';
      case 'lg': return 'py-2 px-4 text-lg';
      default: return 'py-1 px-2 text-xs';
    }
  };

  return (
    <span className={`inline-block rounded-full ${getSizeClass(size)} ${getStatusBadgeClass(status)}`}>
      {getStatusText(status)}
    </span>
  );
};

export default TenantStatus;