import React, { useState, useEffect } from 'react';
import { getKubeconfig } from 'services/api/tenantService';
import { toast } from 'react-toastify';
import { FaCopy, FaDownload } from 'react-icons/fa';
import Loading from 'views/loading/Loading';

const KubeconfigFetcher = ({
  tenantId,
  organizationId,
  tenantName,
  organizationName,
}) => {
  const [kubeconfig, setKubeconfig] = useState('');
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchKubeconfig = async () => {
      setLoading(true);
      try {
        const response = await getKubeconfig(tenantId, organizationId);
        setKubeconfig(response.data.kubeConfig);
      } catch (err) {
        console.error(`Failed to fetch kubeconfig: ${err.message}`);
        toast.error('Failed to fetch kubeconfig', {
          autoClose: 3000,
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchKubeconfig();
  }, [tenantId, organizationId]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(kubeconfig)
      .then(() => {
        setCopied(true);
        toast.success('Kubeconfig copied to clipboard!', {
          autoClose: 2000,
          position: 'top-center',
        });
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => {
        toast.error('Failed to copy kubeconfig.', {
          autoClose: 3000,
          position: 'top-center',
        });
      });
  };

  const downloadKubeconfig = () => {
    const fileName = `${tenantName || 'tenant'}-${organizationName || 'org'}-kubeconfig.yaml`;

    const blob = new Blob([kubeconfig], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="max-w-7xl mx-auto">
      <h4 className="text-sm font-bold text-gray-800 pb-2 mb-6 lg:text-lg dark:text-white">
        Tenant Admin Access
      </h4>

      {loading ? (
        <Loading />
      ) : (
        kubeconfig && (
          <div className="mt-8 flex space-x-4">
            <button
              onClick={copyToClipboard}
              className="flex items-center bg-customBlue hover:bg-gray-800 text-white text-[8px] font-semibold py-2 px-4 rounded-md lg:text-xs dark:bg-navy-700 dark:hover:bg-navy-800"
            >
              <FaCopy className="mr-2" />{' '}
              {copied ? 'Copied!' : 'Copy Kubeconfig'}
            </button>

            <button
              onClick={downloadKubeconfig}
              className="flex items-center bg-green-700 hover:bg-gray-800 text-white shadow-md text-[8px] font-semibold py-2 px-4 rounded-md lg:text-xs dark:bg-green-900 dark:hover:bg-navy-800"
            >
              <FaDownload className="mr-2" /> Download Kubeconfig
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default KubeconfigFetcher;
