import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';

const Security = () => {
  const [apiKey, setApiKey] = useState('*************');
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  //const [currentPassword, setCurrentPassword] = useState('');
  //const [newPassword, setNewPassword] = useState('');

  const toggleApiKeyVisibility = () => {
    if (isApiKeyVisible) {
      setApiKey('*************');
    } else {
      setApiKey('12345-ABCDE-67890-FGHIJ');
    }
    setIsApiKeyVisible(!isApiKeyVisible);
  };

  const copyApiKeyToClipboard = () => {
    navigator.clipboard.writeText(apiKey).then(
      () => {
        toast.success('Api keys copied to clipboard!.', {
          autoClose: 2000,
          position: 'top-center',
        });
      },
      err => {
        console.error('Could not copy API key:', err);
        toast.error('Could not copy API key', {
          autoClose: 2000,
          position: 'top-center',
        });
      },
    );
  };

  // const updatePassword = async () => {
  //   try {
  //     console.log('Password updated successfully');
  //     setCurrentPassword('');
  //     setNewPassword('');
  //   } catch (error) {
  //     console.error('Failed to update password:', error);
  //   }
  // };

  return (
    <div className="bg-white dark:bg-navy-700 shadow-md rounded-lg p-6">
      <div className="flex items-center justify-between border-b pb-3 mb-4 dark:border-gray-700">
        <h3 className="text-md font-semibold text-gray-700 dark:text-white">
          Security
        </h3>
        <button className="text-sm text-blue-400 hover:underline">
          Update Password
        </button>
      </div>
      <div className="space-y-4">
        {/* Password Section */}
        <div className="flex gap-6 items-center">
          <p className="text-sm text-gray-700 whitespace-nowrap dark:text-gray-400 w-24">
            Current Password:
          </p>
          <p className="dark:text-white">***********</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm text-gray-700 dark:text-gray-400 w-24 ">
            New Password:
          </p>
          {/* <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="bg-black border rounded px-2 py-1 dark:bg-navy-700 dark:text-white"
            autoComplete="off"
          /> */}
        </div>
        {/* API Key Section */}
        <div className="mt-6">
          <h4 className="text-sm font-medium text-gray-700 dark:text-white mb-2">
            API Key:
          </h4>
          <div className="flex items-center gap-4">
            <input
              type="text"
              value={apiKey}
              readOnly
              className="border rounded px-3 py-1 w-full dark:bg-navy-700 dark:text-white dark:border-gray-700"
            />
            <button
              onClick={toggleApiKeyVisibility}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label={isApiKeyVisible ? 'Hide API Key' : 'Show API Key'}
            >
              {isApiKeyVisible ? (
                <FaEyeSlash className="w-5 h-5" />
              ) : (
                <FaEye className="w-5 h-5" />
              )}
            </button>
            <button
              onClick={copyApiKeyToClipboard}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label="Copy API Key"
            >
              <FaCopy className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
