import React, { useState, useEffect } from 'react';
import { exposeApp, getServices } from 'services/api/resourcesService';
import Loading from 'views/loading/Loading';
import { toast } from 'react-toastify';

const ExposeApp = ({
  organizationId,
  tenantId,
  namespaceId,
  organizationName,
  tenantName,
}) => {
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(true);
  const [exposedServices, setExposedServices] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [exposedRoute, setExposedRoute] = useState('');
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const savedExposedServices =
      JSON.parse(localStorage.getItem('exposedServices')) || {};
    setExposedServices(savedExposedServices);

    const fetchServices = async () => {
      setLoadingServices(true);
      try {
        const fetchedServices = await getServices(
          organizationId,
          tenantId,
          namespaceId,
        );
        const filteredServices = fetchedServices
          .filter(service => !service.name.includes('kubernetes'))
          .map(service => ({
            ...service,
            name: `${service.name}-x-default-x-${organizationName}-${tenantName}`,
          }));

        setServices(filteredServices);
      } catch (err) {
        console.error('Failed to fetch services:', err);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchServices();
  }, [organizationId, tenantId, namespaceId, organizationName, tenantName]);

  const handleExposeApp = async (serviceName, port) => {
    try {
      const response = await exposeApp(
        organizationId,
        tenantId,
        namespaceId,
        serviceName,
        port,
      );
      const updatedExposedServices = {
        ...exposedServices,
        [serviceName]: { exposed: true, route: response.fqdn },
      };
      setExposedServices(updatedExposedServices);
      localStorage.setItem(
        'exposedServices',
        JSON.stringify(updatedExposedServices),
      );

      setExposedRoute(response.fqdn);
      setSelectedService(serviceName);
      setShowModal(true);
    } catch (err) {
      console.error(`Error exposing app for service ${serviceName}:`, err);
      toast.error(
        `Oops! We couldn't make the app available for the service "${serviceName}"`,
        {
          autoClose: 3000,
          position: 'top-center',
        },
      );
    }
  };

  const handleCopy = route => {
    navigator.clipboard.writeText(route);
    toast.success('Route copied to clipboard!', {
      autoClose: 2000,
      position: 'top-center',
    });
  };

  return (
    <div className="">
      <h3 className="text-xl font-semibold mb-4 dark:text-white">
        Service Information
      </h3>
      {loadingServices ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto">
          <table className="w-[675px] bg-white divide-y divide-gray-200 shadow-md dark:bg-navy-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr className="text-white uppercase text-sm leading-normal">
                <th className="px-6 py-2 text-xs text-left font-medium">
                  Service Name
                </th>
                <th className="px-6 py-2 text-xs text-center font-medium">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {services.length > 0 ? (
                services.map((service, index) => {
                  const port = parseInt(
                    service['port(s)'].split(',')[0].split('/')[0],
                    10,
                  );

                  return (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 dark:hover:bg-navy-600"
                    >
                      <td className="px-6 py-2 text-xs whitespace-nowrap dark:text-white">
                        {service.name}
                      </td>
                      <td className="flex justify-center items-center px-6 py-2 text-center">
                        {exposedServices[service.name]?.exposed ? (
                          <button
                            onClick={() => {
                              setExposedRoute(
                                exposedServices[service.name].route,
                              );
                              setSelectedService(service.name);
                              setShowModal(true);
                            }}
                            className="flex items-center bg-green-700 text-white whitespace-nowrap font-bold py-1 px-2 mr-3 rounded text-xs hover:bg-green-800 lg:px-4 lg:text-xs dark:bg-green-800 dark:hover:bg-green-900"
                          >
                            View Route
                          </button>
                        ) : (
                          <button
                            onClick={() => handleExposeApp(service.name, port)}
                            className="bg-customBlue text-white font-bold py-1 px-3 rounded mr-2 text-xs dark:bg-navy-600 dark:hover:bg-navy-700 hover:bg-gray-800"
                          >
                            Expose
                          </button>
                        )}
                        <button
                          onClick={() => service.name}
                          className="bg-red-600 text-white font-bold py-1 px-3 rounded text-xs hover:bg-red-700 dark:bg-red-800 dark:hover:bg-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    className="py-3 px-6 text-center text-gray-600 dark:text-gray-400"
                  >
                    No services available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal for displaying exposed route */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 dark:bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg lg:p-7 dark:bg-navy-700">
            <h4 className="text-xs font-semibold mb-4 lg:text-lg dark:text-white">
              Exposed Route for {selectedService}
            </h4>
            <p className="mb-4 text-xs lg:text-sm dark:text-white">
              Access your service at:
              <a
                href={`https://${exposedRoute}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 text-sm ml-2 border p-2 rounded-md shadow-md"
              >
                https://{exposedRoute}
              </a>
            </p>
            <button
              onClick={() => handleCopy(`https://${exposedRoute}`)}
              className="bg-green-700 text-white font-bold py-1 px-3 rounded text-xs hover:bg-green-800 dark:bg-green-800 dark:hover:bg-green-900 mr-2"
            >
              Copy
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="bg-gray-600 text-white font-bold py-1 px-3 rounded text-xs hover:bg-gray-800 dark:bg-gray-800 dark:hover:bg-gray-900"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExposeApp;
